/*====================  
    5.3 About Page CSS
====================*/
.about-banner-image {
    position: relative;
    background-image: url(../images/about/large-about.jpg);
    padding: 500px 0px;
    text-align: center;
    background-attachment: fixed;
    background-size: cover;
    @media #{$desktop-device} {
        padding: 450px 0px;
    }
    @media #{$tablet-device} {
        padding: 350px 0px;
    }
    @media #{$large-mobile} {
        padding: 300px 0px;
    }            
    @media #{$extra-small-mobile} {
        padding: 250px 0px;
    }
    & .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        
        & .title {
            color: $white;
            font-weight: 600;
            font-size: 130px;
            letter-spacing: 50px;
            text-transform: uppercase;
            text-indent: 50px;

            @media #{$desktop-device} {
                font-size: 110px;
                letter-spacing: 30px;
                text-indent: 30px;
            }
            @media #{$tablet-device} {
                font-size: 100px;
                letter-spacing: 10px;
                text-indent: 10px;
            }
            @media #{$large-mobile} {
                font-size: 80px;
                letter-spacing: 5px;
                text-indent: 5px;
            }            
            @media #{$extra-small-mobile} {
                font-size: 60px;
                letter-spacing: 0px;
            }

        }
    }
}
.work-image-bg {
    position: relative;
    background-image: url(../images/about/large-about-2.jpg);
    padding: 170px 0px 170px;
    background-attachment: fixed;
    background-size: cover;
    @media #{$tablet-device} {
        padding: 120px 0;
    }
    @media #{$large-mobile} {
        padding: 100px 0;
    }   

    & .content{
        position:relative;

        & .video-box{
            position: absolute;
            right: 0px;
            top: 80px;
            color: $white;
            font-size: 20px;
            font-weight: 600;
            text-align: right;
            text-transform: uppercase;

            @media #{$large-mobile} {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
                top: 0;
                margin-top: 50px;
            }

            & .fa-play{
                position: relative;
                width: 90px;
                height: 90px;
                color: $white;
                display: block;
                float: right;
                margin-top: 50px;
                text-align: center;
                line-height: 90px;
                border-radius: 50px;
                background-color: $primary;
                font-size: 50px;

                & .ripple, & .ripple::before, & .ripple::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 90px;
                    width: 90px;
                    margin-left:.5px;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    box-shadow: 0 0 0 0 rgba(255, 255, 255, .4);
                    animation: ripple 3s infinite;
                }

                & .ripple {
                    &::before {
                        animation-delay: .9s;
                        content: "";
                        position: absolute;
                    }
                    &::after {
                        animation-delay: .6s;
                        content: "";
                        position: absolute;
                    }
                }
            }
        }

        & .subtitle{
            position: relative;
            color: $white;
            font-weight: 400;
            line-height: 1em;
            font-size: 24px;
            text-transform: uppercase;

            @media #{$large-mobile} {
                font-size: 20px;
            }
        }

        & .title{
            position: relative;
            color: $white;
            font-weight: 600;
            line-height: 1.1em;
            margin-top: 45px;
            font-size: 110px;
            @media #{$desktop-device} {
                font-size: 90px;
            }
            
            @media #{$tablet-device} {
                font-size: 60px;
            }

            @media #{$large-mobile} {
                font-size: 50px;
                margin-top: 30px;
            }

            @media #{$extra-small-mobile} {
                font-size: 36px;
                margin-top: 20px;
                line-height: 1.3;
            }
        }

        & p{
            position: relative;
            color: $white;
            font-weight: 400;
            line-height: 1.7em;
            font-size: 18px;
            margin-top: 50px;
            margin-bottom: 85px;
            
            @media #{$tablet-device} {
                font-size: 16px;
                width: 510px;
            }
            @media #{$large-mobile} {
                font-size: 16px;
                width: 100%;
                margin: 30px 0 40px 0;
            }
            @media #{$extra-small-mobile} {
                font-size: 14px;
            }
        }
        
        & .pdf-file{
            position: relative;
            color: $white;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            transition: $transition-base;
            display: flex;
            align-items: center;

            @media #{$large-mobile} {
                font-size: 18px;
                line-height: 1;
            }

            & .icon{
                font-size: 36px;
                line-height: 1em;
                margin-right: 10px;           
                
                @media #{$large-mobile} {
                    font-size: 26px;
                }
            }

            &:hover {
                color: $primary;
            }
        }
    }
}
@-webkit-keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

@keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
    100% {box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}