/*====================  
    4.7 Brand Logo CSS
====================*/
.single-brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    & a {
        position: relative;

        & img {
            transform: scale(.9);
            transition: $transition-base;
        }

        &:hover {

            & img {
                transform: scale(1);
                transition: $transition-base;
                filter: invert(44%) sepia(74%) saturate(976%) hue-rotate(43deg) brightness(96%) contrast(102%);
            }
        }
    }
}
.brand-logo-carousel {
    padding: 100px 0;
    @media #{$tablet-device} {
        padding: 80px 0;
    }
    @media #{$large-mobile} {
        padding: 60px 0;
    }
}